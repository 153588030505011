<template>

    <div class="section">
        <div class="columns is-centered" >
            <div class="column box is-12-mobile is-9-tablet is-8-desktop is-8-widescreen" v-if="plan">
                <h1 class="title is-1">{{  $t("You've Chosen the {plan} plan", {plan: $t(plan.name)}) }}</h1>
                <h3 class="subtitle"><b-button tag="router-link" :to="{name: 'subscription'}" type="is-text">{{$t('Return to the plans list')}}</b-button></h3>
                <b-field label="period">
                    <b-select v-model="paymentPeriod">
                        <option value="month_price">month</option>
                        <option value="year_price">year</option>
                    </b-select>
                </b-field>
                <b-button class="is-success"
                          size="is-large"
                          @click.prevent="handleCreateInvoice">
                    {{$t('Proceed to checkout')}} {{plan[paymentPeriod]}} грн.
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import {GET_SUBSCRIPTION_PLAN} from "@/store/modules/subscriptions/action-types";
import {mapActions} from "vuex";
import {CREATE_INVOICE} from "@/store/modules/invoices/action-types";
import formHelperMixin from "@/mixins/formHelperMixin";

export default {
    name: "Plan",
    title(){ return this.$t('Subscription plan');},
    data(){
        return {
            plan: null,
            paymentPeriod: 'month_price',
        };
    },
    methods: {
        ...mapActions('subscriptions', [
            GET_SUBSCRIPTION_PLAN,
        ]),
        ...mapActions('invoices', [
            CREATE_INVOICE,
        ]),
        handleCreateInvoice(){

            this[CREATE_INVOICE]({
                'plan': this.plan.id,
                'period': this.paymentPeriod.split('_')[0],
            }).then(res => {
               this.$router.push({
                   name: 'checkout',
                   params: {
                       invoice: res.data,
                       invoice_id: res.data.id,
                   }
               });
            })
            .catch(() => {
                this.$buefy.toast.open({
                    message: `Error`,
                    type: 'is-danger'
                });
            });
        },
    },
    beforeRouteEnter(to, from , next){
        next(vm => {
            if (!vm.$route.params.plan){
                vm[GET_SUBSCRIPTION_PLAN](vm.$route.params.plan_id).then(res => {
                    vm.plan = res.data;
                });
            } else {
                vm.plan = vm.$route.params.plan;
            }
        });
    },
    created() {
        this.$Progress.finish();
    },
    mixins: [formHelperMixin]
};
</script>

<style scoped>

</style>